import moment from 'moment';
import 'moment/dist/locale/sv';

moment.locale('sv');

export {
  moment,
};

declare module 'vue/types/vue' {
  interface Vue {
    moment: typeof moment
  }
}
